import { Typography, Box, Avatar, Card, CardContent, styled, useTheme, FormControl, useMediaQuery } from '@mui/material';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import { useContext, useEffect, useRef, useState } from 'react';

import {
  addDays,
  addHours,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { CustomProvider, DateRangePicker } from "rsuite";
import esES from "rsuite/locales/es_ES";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

import { AuthContext } from 'src/contexts/FirebaseAuthContext';
import useAgentStore from 'src/hooks/UseStore';
import DownloadReport from './DownloadReport';

import { predefinedRanges } from 'src/utils/core';

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
    background: ${theme.colors.gradients.orange1};
    color:  ${theme.palette.primary.contrastText};
  `
);

function Sales() {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState(0);
  const agentType = useAgentStore((state) => state.agentType);
  const [dateRange, setDateRange] = useState([
    new Date(dayjs.tz(new Date(), "America/El_Salvador").set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0)),
    new Date(dayjs.tz(new Date(), "America/El_Salvador").set("hour", 23).set("minute", 59).set("second", 59).set("millisecond", 999))
  ]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let id_usuario;

  if(agentType === 'Agente'){
    id_usuario = user?.id_agente;
  } else if(agentType === 'SubConcesionario'){
    id_usuario = user?.id_subconcesionario;
  } else if(agentType === 'Concesionario'){
    id_usuario = user?.id_concesionario;
  }


  useEffect(() => {
    fetch(
      `/api/sales?id_usuario=${id_usuario}&startDate=${dateRange[0].toISOString()}&endDate=${dateRange[1].toISOString()}`
    )
      .then((response) => response.json())
      .then((data) => {
        const sum = data.reduce((acc, curr) => acc + Number(curr.monto), 0);
        const roundedSum = Math.round(sum * 100) / 100;
        const formattedSum = roundedSum.toFixed(2);
        setData(data.length ? formattedSum : '0.00');
      })
      .catch((error) => {
        console.error("Error fetching sales count:", error);
      });
  }, [dateRange]);

  const columns = [
    { header: "ID Transacción", key: "id_transaccion", width: 25 },
    { header: "Fecha", key: "fecha", width: 20 },
    { header: "Monto", key: "monto", width: 15 },
    { header: "Tipo", key: "tipo", width: 15 },
    { header: "ID Autor", key: "id_autor", width: 15 },
  ];


  const handleChangeDateRange = (event) => {
    const [startDate, endDate] = event;
    const timeZone = "America/El_Salvador";
    const newStartDate = new Date(dayjs.tz(startDate,timeZone).set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0));
    const newEndDate = new Date(dayjs.tz(endDate,timeZone).set("hour", 23).set("minute", 59).set("second", 59).set("millisecond", 999));
    setDateRange([newStartDate, newEndDate]);
  };

  const isSameDate = (date1, date2) => date1.toISOString() === date2.toISOString();

  const renderDateRangeLabel = (value) => {
    if (!value || value.length !== 2) return "Rango de fechas";
    const [startDate, endDate] = value;
    for (const range of predefinedRanges) {
      const [rangeStart, rangeEnd] = range.value;
      if (
        (isSameDate(startOfDay(startDate), rangeStart) &&
          isSameDate(endOfDay(endDate), rangeEnd)) ||
        (isSameDate(
          startDate,
          dayjs.tz(rangeStart, "America/El_Salvador")
        ) &&
          isSameDate(endDate, dayjs.tz(rangeEnd, "America/El_Salvador")))
      ) {
        return range.label;
      }
    }
    return `${startDate.toLocaleDateString()} a ${endDate.toLocaleDateString()}`;
  };

  const predefinedRanges = [
    {
      label: "Hoy",
      value: [
        new Date(),
        new Date(),
      ],
    },
    {
      label: "Ayer",
      value: [
        addDays(new Date(), -1),
        addDays(new Date(), -1),
      ],
    },
    {
      label: "Esta semana",
      value: [
        startOfWeek(new Date(), { weekStartsOn: 1 }),
        endOfWeek(new Date(), { weekStartsOn: 1 }),
      ],
    },
    {
      label: "Semana pasada",
      value: [
        startOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 }),
        endOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 })
      ],
    },
    {
      label: "Este mes",
      value: [
        startOfMonth(new Date()),
        endOfMonth(new Date()),
      ],
    },
    {
      label: "Mes pasado",
      value: [
        startOfMonth(addMonths(new Date(), -1)),
        endOfMonth(addMonths(new Date(), -1)),
      ],
    },
    {
      label: "Este año",
      value: [startOfYear(new Date()), endOfYear(new Date())],
    },
  ];


  return (
    <Card sx={{ background: `${theme.colors.gradients.black1}` }}>
      <CardContent>
      <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
              <AvatarWrapper>
              <PointOfSaleIcon sx={{ color: 'lightgreen' }} />
              </AvatarWrapper>
              <Typography
                sx={{ ml: 1 }}
                variant="body1"
                color="white"
                component="div"
              >
                Ventas
              </Typography>
          </Box>
          <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box sx={{ width: "100%", marginLeft: "30px" }}>
                <FormControl fullWidth>
                  <CustomProvider locale={esES} theme={"dark"}>
                    <DateRangePicker
                      size="sm"
                      placement="bottom"
                      appearance="subtle"
                      editable={false}
                      oneTap={false}
                      format="dd-MM-yy"
                      showOneCalendar={isMobile}
                      showHeader={false}
                      placeholder="Rango de fechas"
                      defaultValue={dateRange}
                      weekStart={1}
                      ranges={predefinedRanges}
                      onChange={(value) => {
                        if (value) {
                          handleChangeDateRange(value);
                        } else {
                          handleChangeDateRange([
                            new Date(dayjs.tz(new Date(), "America/El_Salvador").set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0)),
                            new Date(dayjs.tz(new Date(), "America/El_Salvador").set("hour", 23).set("minute", 59).set("second", 59).set("millisecond", 999))
                          ]);
                        }
                      }}
                      renderValue={renderDateRangeLabel}
                    />
                  </CustomProvider>
                </FormControl>
              </Box>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ py: 2 }}
        >
          <Typography variant="h2" color="white" sx={{ mr: 1 }}>
            $ {data !== null ? data : 0}
          </Typography>
        </Box>
        <DownloadReport
          id={id_usuario}
          paramKey={"id_usuario"}
          apiEndpoint="/api/sales"
          worksheetName="Ventas"
          fileName="ventas"
          columns={columns}
          isRange={true}
          startDate={dateRange[0].toISOString()}
          endDate={dateRange[1].toISOString()}
        />
      </CardContent>
    </Card>
  );
}

export default Sales;