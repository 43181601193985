import {
    addDays,
    addHours,
    addMonths,
    endOfDay,
    endOfMonth,
    endOfWeek,
    endOfYear,
    startOfMonth,
    startOfWeek,
    startOfYear,
  } from "date-fns";
  
  import dayjs from "dayjs";
  import utc from "dayjs/plugin/utc";
  import timezone from "dayjs/plugin/timezone";
  dayjs.extend(utc);
  dayjs.extend(timezone);
  
  export const predefinedRanges = [
    {
      label: "Hoy",
      value: [
        dayjs.tz(new Date(), "America/El_Salvador"),
        dayjs.tz(new Date(), "America/El_Salvador"),
      ],
    },
    {
      label: "Ayer",
      value: [
        addDays(addHours(new Date(), -6), -1),
        addDays(addHours(new Date(), -6), -1),
      ],
    },
    {
      label: "Esta semana",
      value: [
        startOfWeek(new Date(), { weekStartsOn: 1 }),
        endOfWeek(new Date(), { weekStartsOn: 1 }),
      ],
    },
    {
      label: "Semana pasada",
      value: [
        startOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 }),
        endOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 }),
      ],
    },
    {
      label: "Este mes",
      value: [startOfMonth(new Date()), endOfMonth(new Date())],
    },
    {
      label: "Mes pasado",
      value: [
        startOfMonth(addMonths(new Date(), -1)),
        endOfMonth(addMonths(new Date(), -1)),
      ],
    },
    {
      label: "Este año",
      value: [startOfYear(new Date()), endOfYear(new Date())],
    },
  ];